/**
 * Inject js into <head> 
 * @param {String} path - Path to script 
 */
function injectScript(path) {
    const scriptElement = document.createElement('script');

    scriptElement.async = false;
    scriptElement.src = path;
    document.head.appendChild(scriptElement);
}

/**
 * URLSearchParams
 */
if (!Modernizr.urlsearchparams) {
  injectScript('/themes/custom/artbank/assets/dist/js/url-search-params-polyfill.js');
}

/**
 * es6 Arrays Polyfill
 */
if (!Modernizr.es6array) {
    injectScript('/themes/custom/artbank/assets/dist/js/es6-shim.min.js');

    // Remove fade up effect from some browsers 
    const nodes = document.querySelectorAll('.effect-fade-up');

    for (var i = 0; i < nodes.length; i++) {
      nodes[i].classList.remove('effect-fade-up');
    }

}

/**
 * Object-fit polyfill
 */
if (!Modernizr.objectfit) {

  document.addEventListener('DOMContentLoaded', function () {
    injectScript('/themes/custom/artbank/assets/dist/js/object-fit-polyfill.min.js');

    setTimeout(function () {
      if (objectFitImages) {
        objectFitImages();
      }
    }, 2000);

  })

}